import { Link, useOutletContext } from "@remix-run/react";
import { useState } from "react";
import type { OutletContext } from "~/utils/types";
import { FcGoogle } from "react-icons/fc";
import { Container } from "./Container";

export function AuthLayout({
  type,
  onSubmit,
}: {
  type: "login" | "signup";
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<{ error?: string; success: boolean }>;
}) {
  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState("");
  return (
    <Container className="flex flex-col justify-center mt-6">
      <div className="w-full mx-auto rounded-md lg:max-w-md">
        <SocialLogin />
        <div className="flex items-center w-full">
          <hr className="w-full" />
          <p className="px-3 ">OR</p>
          <hr className="w-full" />
        </div>
        <form
          method="post"
          className="space-y-4"
          onSubmit={async (e) => {
            e.preventDefault();
            setInProgress(true);
            setError("");
            const { error } = await onSubmit(e);
            setInProgress(false);
            setError(error || "");
          }}
        >
          <EmailPassword />
          <div>
            <button className="btn btn-block btn-primary">
              {inProgress ? <span className="loading loading-dots loading-sm" /> : null}
              {type === "login" ? "Sign In" : "Sign Up"}
            </button>
          </div>
          {error && <div className="text-error">{error}</div>}
          <div className="mt-2 text-sm">
            {type === "login" ? "Don't have an account?" : "Already have an account?"}&nbsp;
            <Link to={type === "login" ? "/signup" : "/login"} className="link">
              {type === "login" ? "Sign Up" : "Sign In"}
            </Link>
          </div>
        </form>
      </div>
    </Container>
  );
}

export const SocialLogin = () => {
  const { supabase } = useOutletContext<OutletContext>();

  const google = () => {
    supabase.auth.signInWithOAuth({
      provider: "google",
      options: { redirectTo: `${window.location.origin}/` },
    });
  };

  return (
    <div className="mb-6 space-y-2">
      <button
        aria-label="Login with Google"
        type="button"
        className="flex w-full btn btn-outline gap-4"
        onClick={google}
      >
        <FcGoogle className="w-6 h-6" />
        <span>Login with Google</span>
      </button>
    </div>
  );
};

export const EmailPassword = () => {
  return (
    <>
      <div>
        <label className="label">
          <span className="text-base label-text">Email</span>
        </label>
        <input type="email" name="email" placeholder="Email Address" className="w-full input input-bordered" required />
      </div>
      <div>
        <label className="label">
          <span className="text-base label-text">Password</span>
        </label>
        <input
          type="password"
          name="password"
          placeholder="Enter Password"
          className="w-full input input-bordered"
          minLength={2}
          required
        />
      </div>
    </>
  );
};
